import './App.css';
import DistrictListing from './pages/DisctrictListing';
import { ExternalLink } from 'lucide-react';
import logo from "./assets/logo.png"
import React from 'react';

function App() {

  const footerLinks = [
    { name: 'Admission', url: 'https://teacherbuilder.com/admission' },
    { name: 'Certification Areas', url: 'https://teacherbuilder.com/certification-areas' },
    { name: 'How It Works', url: 'https://teacherbuilder.com/how-it-works' },
    { name: 'Resources', url: 'https://teacherbuilder.com/resources' },
    { name: 'FAQ', url: 'https://teacherbuilder.com/faq' }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      {/* TeacherBuilder Navbar */}
      <nav className="bg-white border-b border-gray-200 py-4 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            {/* Logo Section */}
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <a href="https://jobs.teacherbuilder.com" className="flex items-center">
                  <img 
                    src={logo}
                    alt="TeacherBuilder.com" 
                    className="h-16 w-auto"
                  />
                </a>
              </div>
            </div>

            {/* Right Side Links */}
            <div className="flex items-center space-x-4">
              <div className="hidden md:flex items-center space-x-1">
                {/* Phone Numbers */}
                <div className="text-sm">
                  <span className="text-gray-500">Admissions:</span>
                  <a href="tel:+19564460675" className="text-[#56A0D2] ml-1 hover:text-blue-800">
                    +1 (956) 446-0675
                  </a>
                </div>
                <span className="text-gray-300">|</span>
                <div className="text-sm">
                  <span className="text-gray-500">HR:</span>
                  <a href="tel:+18883084661" className="text-[#56A0D2] ml-1 hover:text-blue-800">
                    +1 (888) 308-4661
                  </a>
                </div>
              </div>

              {/* Visit Site Button */}
              <a
                href="https://teacherbuilder.com"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 border border-[#56A0D2] text-sm font-medium rounded-md text-[#56A0D2] hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ExternalLink className="h-4 w-4 mr-1" />
                Visit TeacherBuilder.com
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex-grow">
        <DistrictListing />
      </div>

      {/* TeacherBuilder Footer */}
      <footer className="bg-[white] text-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center">
            {/* Logo and Tagline */}
            <div className="mb-6 text-center">
              <img 
                src={logo} 
                alt="TeacherBuilder.com" 
                className="h-16 mx-auto mb-3"
              />
            
            </div>

            {/* Navigation Links */}
            <nav className="flex flex-wrap justify-center gap-x-6 gap-y-2 mb-6">
              {footerLinks.map((link, index) => (
                <React.Fragment key={link.name}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-white text-sm transition-colors duration-200"
                  >
                    {link.name}
                  </a>
                  {index < footerLinks.length - 1 && (
                    <span className="text-gray-400">•</span>
                  )}
                </React.Fragment>
              ))}
            </nav>

            {/* Copyright */}
            <div className="text-center text-gray-600 text-xs">
              © {new Date().getFullYear()} TeacherBuilder.com. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;